<template>

	  <div>
	  	{{ tracks }}
	  	<div id="masterplan" @click="graphclicked"></div>

	  </div>


</template>



<script type="text/javascript">
	
</script>

<script>


	import Userapi from '@/services/api/users'
	import ApexCharts from 'apexcharts'

	export default{
		name:'gantt1',
		data(){
			return{
				savedpng:null,
				graphworking:false,
				milestonelabelbox : [],
				milestonelabeltext : [],
				milestonemarkers : [],
				gridcolor1: '#111',
				gridcolor2: '#111',
				trackcolor : '#B5C3E3', //b200
				tracktextcolor : '#313338', //n900				
				cpcolor : '#da6060', //R300
				notcpcolor : '#696f7b', //N700
				lastzoom:null,
				zoom2:null,
				tracks:[],
				trackdates:{ formstart:{ status:0, value:"" }, formend:{ status:0, value:"" } , editstart:{ status:0, value:"" }, editend:{ status:0, value:"" }, milestones:{ status:0, value:"" } },
				activechart:null,
				dataoptions : {
	          		series: [],
	          		noData : {
					  text: 'No available data',
					  align: 'center',
					  verticalAlign: 'middle',
					  offsetX: 0,
					  offsetY: 0,
					  style: {
					    color: undefined,
					    fontSize: '14px',
					    fontFamily: undefined
					  }
					},
					states: {
					    normal: {
			        		filter: {
			            		type: 'none',
			            		value: .15,
			        		}
			    		},
				    	hover: {
				        	filter: {
				           		type: 'darken',
				            	value: 0.75,
				        	}
				    	},
				    	active: {
				        	filter: {
				            	type: 'darken',
				            	value: 0.75,
				        	}
				    	},
					},
	          		chart: {
	          			id: 'masterplanchart',
	          			redrawOnParentResize: true,
	          			 animations: {
        						enabled: false
        					},
	          			zoom: { enabled: true },
	          			height: 250,
	          			type: 'rangeBar',
	          			background: '#fff',
						toolbar: { show: true, tools: {
						  download: false,
						  selection: true,
						  zoom: true,
						  zoomin: true,
						  zoomout: true,
						  pan: true,
						  reset: true | '<img src="/static/icons/reset.png" width="20">',
						},
						export:{}
						},
	          		events : this.chartEvents(),	          		
	        		},
	        		plotOptions: {
			          bar: {
			          	barHeight: '50%',
			          	borderRadius: 0,
			            horizontal: true,
			            distributed: false,
			            dataLabels: {
			              hideOverflowingLabels: false,
			              position: 'bottom'
			            },
		             	colors: {
		             	  backgroundBarColors:'#000',	
			              backgroundBarOpacity: 1
			          	},
			          }
			        },
			        
			        tooltip: {
      					enabled: false,
	      					custom: function({series, seriesIndex, dataPointIndex, w}) {

	      						//let newdate = new Date( series[seriesIndex][dataPointIndex] )

	      						//console.log( this. dataPointIndex )

	      						var rawlabel = w.globals.labels[dataPointIndex]
			            		let labels = rawlabel.split('x|x')

						    	return '<div class="p-2">' + labels[1] + '</div>'
						  	}
      				},
			        dataLabels: {
			          textAnchor: 'start',
			          enabled: true,
			          offsetY: -5,
			          offsetX: -35,
			          formatter: this.datalabelformatter,
			          style: {
			            colors: [this.tracktextcolor, '#fff']
			          }
			        },
			        xaxis: {
			          type: 'datetime',
			          labels:{show:true, textAnchor: 'end'}
			        },
			        yaxis: {
			          show: false,
			          labels:{show:true, textAnchor: 'end'}
			        },
			        legend: {
	          				show: false,
	          				showForSingleSeries: true,
	        //  customLegendItems: ['Actual', 'Expected'],
	         		 		markers: {
	            		fillColors: ['#00E396', '#775DD0']
	          		}
	        		},
	        		fill: {
			  		opacity: 1,
					},

			        grid: {
			          borderColor: '#696f7b',
					  row: {
					      colors: ['transparent', 'transparent'],
					      opacity: 1
					  },  
					  xaxis: {
					    lines: {
					      //show: true
					    }
					  }
					},
        		},
			}
		},
		props:{
			alltracks:Array,
			colors:Object,
		},
		watch:{

			
			alltracks : function( e,v ){

				let self = this

				this.tracks = e


				//e.then( function(response){

					
	              let newseries = []
	              let xpoints = []

						if( this.tracks.length > 0 ){					

							let mintrackstart = new Date()
							let maxtrackend = new Date()

							for( let i in this.tracks ){

								let currentd = new Date( this.tracks[i].trackstart )

								if( currentd < mintrackstart ){
									mintrackstart = currentd
								}
							}

							for( let i in this.tracks ){


								//if( !self.activetrack._id ){

								//	self.activetrack = this.tracks[i]
								//	self.trackdates.editstart.value = self.activetrack.trackstart
								//	self.trackdates.editend.value = self.activetrack.trackend
								//}


                				let milestones = []

                				let xaxi = this.tracks[i]._id//+'x|x'+response.data.tracks[i].label

                				if( this.tracks[i].milestones ){

                					for( let o in this.tracks[i].milestones ){                						

                						let criticalcolor = this.tracks[i].milestones[o].iscritical ? self.cpcolor : self.notcpcolor

                						let milestone = { name: this.tracks[i].milestones[o].label, value: new Date( this.tracks[i].milestones[o].eventdate ).getTime(), strokeColor: criticalcolor , strokeWidth: 10, strokeHeight: 0, strokeLineCap: 'round',  mouseEnter: function(e) {
											    alert('cc')
											}}

                    					milestones.push(milestone)


                    					//let xpoint = []
                    					//+ ' - ' + response.data.tracks[i].milestones[o].eventdate
										xpoints.push( {  y:xaxi, x:milestone.value, marker: { size: 6, fillColor: "#000",
      strokeColor: "#000" }, label: { text:this.tracks[i].milestones[o].label,  offsetY: 10000,  } } )
                					}
                				}

                				let trackcolor = this.colors.blue

                				if( this.tracks[i].status && this.tracks[i].status == 'green' ){
                					trackcolor = this.colors.green
                				}
                				if( this.tracks[i].status && this.tracks[i].status == 'red' ){
                					trackcolor = this.colors.red
                				}
                				if( this.tracks[i].status && this.tracks[i].status == 'yellow' ){
                					trackcolor = this.colors.yellow
                				}

                				//this.tracks[i].status
								let series = { x:'', y:[], fillColor: trackcolor, goals:milestones }

								series.x = xaxi



								let trackstart = new Date( this.tracks[i].trackstart ).getTime()
								let trackend = new Date( this.tracks[i].trackend ).getTime()

								if(  isNaN(trackstart) || isNaN(trackend) ){
									
									trackstart = mintrackstart.getTime()
									//new Date()
									//trackstart.setDate(trackstart.getDate() - 1);
									//trackstart

									trackend = new Date().getTime()
									series.fillColor = '#CD0000'
								}

								series.y.push( trackstart )
								series.y.push( trackend )

								newseries.push( series )

							}						
							
							

						}

						if( self.activechart ){
							

							let chartheight = 100 + ( this.tracks.length * 56 )

							self.activechart.updateOptions( { chart: { height : chartheight  }, series: [{ name: 'Gantt', data : newseries }] }, false  )


							setTimeout( function(){

								self.activechart.updateOptions( { annotations : { position: 'front' ,
								   xaxis: [{ x: new Date().getTime()}],
								  points: xpoints
								}  }, false  )


							}, 50)


							if (self.lastzoom) {
							    self.activechart.zoomX(self.lastzoom[0], self.lastzoom[1]);
							 }

						}

						
				
					//})

			}
			
		},
		mounted(){


			let self = this						


			setTimeout( function(){

				self.activechart = new ApexCharts(document.querySelector("#masterplan"), self.dataoptions);
        		self.activechart.render();

			},50)	
			

			this.trackname = ""
			this.plandescription = ""

			

		},
		methods:{

			savemeacopy(){


				let self = this

				this.activechart.dataURI().then(({ imgURI }) => {
    				//self.$emit('ondumppng', imgURI)
    				self.savedpng = imgURI
	        		Userapi.savePlanPng( { "field":"savepng", "boardid": this.cockpitid, "t": this.token, "imgurl": imgURI } )
	      		})

			},

			rendercriticalpath(){

				let self = this

				let milestoneYs = []
				let milestoneXs = []

				let baroffset = '-6'


		       	let parent = document.getElementById('apexchartsmasterplanchart');

		       	let isrendered = parent.querySelector('#masterplancriticalpath')

		       	if( !parent || isrendered ){
		       		return false
		       	}

		       	let rangebars = parent.querySelectorAll('.apexcharts-rangebar-area')

		       	for( let i in rangebars ){
		       		if( rangebars[i] && typeof rangebars[i].hasAttribute === 'function' &&  rangebars[i].hasAttribute('barHeight') === true ){
		       			if( rangebars[i].hasAttribute('barHeight') === true ){
		       				rangebars[i].setAttribute('transform','translate(0,'+baroffset+')');
		       			}
		       		}
		       		
		       	}

		       	let element = parent.querySelector('.apexcharts-svg')

				let goalgroups = parent.querySelectorAll('[className="apexcharts-bar-goals-groups"]');				

					
				let xypath = []
				for( let i in goalgroups ){
					let g = goalgroups[i]

					let lines = []

					let x = 0.0

					let y = 0.0

					if( typeof g.getElementsByTagName === 'function' ){
						
						lines = g.getElementsByTagName('line')

						for( let o in lines ){

							let x = null
							//lines[o].x1
							let y = null

							if( typeof lines[o].hasAttribute === 'function' ){

								/*lines[o].setAttribute("onclick", function(){

									alert('')
								})\\*/

								



								let stroke = lines[o].getAttribute("stroke")

								 // if critical by color

									let pad = 18.0// 20.0 

									x = lines[o].getAttribute("x2")
									let y1 = lines[o].getAttribute("y1")
									let y2 = lines[o].getAttribute("y2")

									lines[o].setAttribute("y1", parseFloat(y1) + pad)
									lines[o].setAttribute("y2", parseFloat(y2) + pad)

									//lines[o].setAttribute("transform", "rotate(45)")

									//lines[o].setAttribute("x2", parseFloat(x) + 30.0)

									x = parseFloat(x) + 27.0
									y = parseFloat(y1) + 23.0

								milestoneYs.push(y)
								milestoneXs.push(x)

								if( stroke == this.cpcolor){
									xypath.push( {x:x,y:y} )
								}								

								self.milestonemarkers.push( lines[o] )
							} 								
							
						}

						
					}
					
				}


			let pointsannotations = parent.querySelector('.apexcharts-point-annotations')

			if( pointsannotations && typeof pointsannotations.getElementsByTagName === 'function' ){


				let Xs = []


				let rects = pointsannotations.getElementsByTagName('rect')

				for( let i in rects ){
					if( rects[i] && typeof rects[i].hasAttribute === 'function'){
						rects[i].setAttribute("y", milestoneYs[i] )
						rects[i].style.display = "none"

						Xs.push( rects[i].getAttribute("x") )

						self.milestonelabelbox.push( rects[i] )
					}
				}				
								
				

			}
				

			let milestonelabels = parent.querySelectorAll('.apexcharts-point-annotation-label');	

			for( let i in milestonelabels ){
	       		if( milestonelabels[i] && typeof milestonelabels[i].hasAttribute === 'function'){
	       			//console.log( milestonelabels[i].getAttribute("y"), "milestonelabels")
	       			milestonelabels[i].setAttribute("y", milestoneYs[i] + 14.0 )
	       			milestonelabels[i].style.display = "none"
	       			self.milestonelabeltext.push( milestonelabels[i] )
	       		}
	       		
	       	}

			function xycompare( a, b ) {
			  if ( a.x < b.x ){
			    return -1;
			  }
			  if ( a.x > b.x ){
			    return 1;
			  }
			  return 0;
			}

			xypath.sort( xycompare );


			let path = null

			let lastx = 0.0
			let lasty = 0.0


			for( let o in xypath ){

				let x = xypath[o].x
				let y = xypath[o].y

				let ypadding = 25						

				if( path ){

					if( x != lastx ){
						path.push( "L "+ lastx + " " + ( y + ypadding) )
					}

					//ypadding = o == (xypath.length-1) ? 0 : ypadding

					path.push( "L "+ x + " " + ( y + ypadding) )
				}else{
					path = ["M "+ x + " " + ( y + ypadding) ]
					path.push( "L "+ x + " " + ( y + ypadding) )
				}

				lastx = x
				lasty = y

			}

			if( path ){

				var pathel = document.createElementNS("http://www.w3.org/2000/svg", 'path'); 
				pathel.setAttribute( "d", path.join(" ")); //Set path's data
				pathel.style.stroke = this.cpcolor; //Set stroke colour
				pathel.style.fill = "none"
				//pathel.style.strokeDasharray="5,5"
				pathel.style.strokeWidth = "2px"; //Set stroke width
				pathel.setAttribute( "id", "masterplancriticalpath")
				

				//element.appendChild(pathel);
				element.insertBefore(pathel,element.firstChild);

				this.criticalpathrendered = true			

			}



			},

			chartEvents(){

				let self = this


				return {
					beforeMount: function (chartContext, config) {
				        console.log( "beforemount")
				      },
			      dataPointSelection: function( event, chartContext, config ) {

			      	let index = config.dataPointIndex

			      	if( self.tracks[index] ){
			      		self.getTrack( self.tracks[index]._id )
			      	}

			      },
			      mounted: function (chartContext, options) {

			      	setTimeout( function(){
			      		//self.rendercriticalpath()
			      	}, 300)
			      	
			      },
			      updated: function(chartContext, config) {

					self.graphworking = true
					console.log( "updated")

			        setTimeout( function(){
			      		//self.rendercriticalpath()
			      		//self.savemeacopy()

			      		self.graphworking = false
			      	}, 300)
			      },
			      beforeResetZoom: function () {
			        self.lastzoom = null;
			      },
			      zoomed: function(_, value ) {
			        self.lastzoom = [value.xaxis.min, value.xaxis.max];
			      }
			    }

			},


			graphclicked: function(e) {

				console.log( e )

				let self = this

				for( let i in this.milestonemarkers ){

					let clientX = e.layerX
					let clientY = e.layerY

					let x = this.milestonemarkers[i].getAttribute("x1")
					let y = this.milestonemarkers[i].getAttribute("y1")

					let found = 0
					let proximity = 6.0

					console.log( this.milestonemarkers[i], "this")

					console.log( clientX, clientY, "client")

					console.log( x, y, "xy")

					if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
						found += 1
					}
					if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
						found += 1
					}

					if( found == 0 ){

						clientX = clientX / 10.0
						clientY = clientY / 10.0


						if( ( clientX - x < proximity ) && ( clientX - x > -proximity )   ){
							found += 1
						}
						if( ( clientY - y < proximity ) && ( clientY - y > -proximity )   ){
							found += 1
						}

					}


					if( found == 2){
						this.milestonelabeltext[i].style.display = "block"
						this.milestonelabelbox[i].style.display = "block"
					}else{
						this.milestonelabeltext[i].style.display = "none"
						this.milestonelabelbox[i].style.display = "none"
					}

					//console.log( found )

					console.log( found , "lines")
				
				}

				//console.log( e )
		        //do stuff
		        e.preventDefault();
		     },


			contextmenu: function(e) {

				//.log( e )
		        //do stuff
		        e.preventDefault();
		     },

		},
		computed:{


			cockpitid(){
        		return this.$route.query.boardid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		}
	}
	//  
</script>