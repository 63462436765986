<template>

	  <div>

	  	<apexgantt :alltracks="alltracks" :colors="colors"></apexgantt>

	  </div>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	import Userapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'

//	import Emailsapi from '@/services/api/emails'
//	import {bus} from '@/main'

	import apexgantt from '@/components/boardcharts/apexgantt'


	
	import moment from 'moment';
	

	export default{
		name:'gantt2',
		data(){
			return{
				alltracks:[],
				colors : { 'green':'#7ece7e', 'red':'#ff9c9c', 'yellow': '#ffe0a5', 'blue' : '#b5c3e3' }
			}
		},
		props:{
		},

		watch:{
			
		},
		mounted(){

			$('#preloader').addClass('hide')

			let self = this

			setTimeout( function(){
				self.getalltracks()
			}, 50)
		},
		methods:{

			getalltracks( callback ){

				let self = this


				Userapi.Tracks( this.cockpitid, this.token, "open" ).then( function(response){

					if( response.data.tracks ){	

						self.alltracks = response.data.tracks
					
					}
				})

			}

			
		},
		computed:{


			cockpitid(){
        		return this.$route.query.boardid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{ apexgantt },

	}
	//  
</script>